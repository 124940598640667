<template>
    <div>

    </div>
</template>

<script>
import {ElMessage} from "element-plus";
import {mapGetters} from "vuex";
import VXETable from "vxe-table";
import {setNowDate} from "@/utils/time";

export default {
    name: "sys_show",
    inject: ['reload'],
    computed: {
        ...mapGetters(['GET_BDST']),
        ...mapGetters(['GET_USER'])
    },
    data() {
        return {
            sys_data: []
        }
    },
    created() {
	    if (!this.$route.params.key){
		    this.$router.push("/manage")
	    }else {
		    this.gotoDataShow()
	    }
    },
    methods: {
        gotoDataShow(){
            this.axios.get('/sysWork/getSysRoomFlag', (response) => {
                this.sys_data = response.obj;
                sessionStorage.setItem("sys_room_data", JSON.stringify(this.sys_data))
                let pathInfo = this.$router.resolve({
                    path:'/sys_show_data',
                    query:{}
                })
                window.open(pathInfo.href, '_self');
            },{
                time: setNowDate(new Date())
            });
        }
    }
}
</script>

<style>

</style>